import React from 'react'
import Layout from '../components/layout'
import Blog from '../components/blog'
import SEO from '../components/seo'
import Privacy from '../components/privacy/privacyNotice'
import ThemeContext from '../context/theme-context'

class PrivacyContent extends React.Component {
    componentDidMount() {
        const { closeMenu } = this.context
        closeMenu()
    }

    render() {
        const { isMobile, darkMode, currentLocale, intl } = this.context

        return (
            <Layout
                showNavLinks={true}
                initialMenuToggle={true}
                hideContact={isMobile}
            >
                <SEO title="Privacy Content" />

                <Privacy />

                <Blog currentLocale={currentLocale} isMobile={isMobile} />
            </Layout>
        )
    }
}

PrivacyContent.contextType = ThemeContext

export default PrivacyContent
